<template>
  <Stack
    direction="row"
    gap="2xs"
    align="end"
    class="pb-sm pl-3xs"
    :data-test-id="`${isFilterTray ? 'mobile-' : ''}plpPriceRange`"
  >
    <Input
      :id="`${generateRandomId()}-priceFrom-${isFilterTray ? 'filter-tray' : 'filter'}`"
      :name="`${generateRandomId()}-priceFrom-${isFilterTray ? 'filter-tray' : 'filter'}`"
      :model-value="model.from"
      class="!mb-none"
      label="Min"
      placeholder="£"
      size="sm"
      @update:input-value="(value) => updateFrom(String(value))"
      data-test-id="plpMinPrice"
      data-fs="plpMinPrice"
    />

    <Input
      :id="`${generateRandomId()}-priceTo-${isFilterTray ? 'filter-tray' : 'filter'}`"
      :name="`${generateRandomId()}-priceTo-${isFilterTray ? 'filter-tray' : 'filter'}`"
      class="!mb-none"
      :model-value="model.to"
      label="Max"
      size="sm"
      placeholder="£"
      @update:input-value="(value) => updateTo(String(value))"
      data-test-id="plpMaxPrice"
      data-fs="plpMaxPrice"
    />

    <Button
      variant="secondary"
      :disabled="model.from.length === 0 && model.to.length === 0"
      @click="updatePrice"
      data-test-id="plpApplyPriceFilterBtn"
    >
      <span class="sr-only">Apply Price Filter</span>
      <Icon name="check-thick" :size="14" />
    </Button>
  </Stack>
</template>

<script lang="ts" setup>
import { generateRandomId } from "mkm-avengers";
import type { PriceFilterProps } from "./categoryTypes";

defineProps<PriceFilterProps>();

type Model = {
  from: string;
  to: string;
};

const model = ref<Model>({
  from: "",
  to: "",
});

const updateFrom = (value: string) => {
  model.value.from = value;
};

const updateTo = (value: string) => {
  model.value.to = value;
};

const emit = defineEmits<{
  (event: "update:price", value: Model): void;
}>();

const updatePrice = () => {
  emit("update:price", model.value);
  model.value = { from: "", to: "" };
};
</script>
